import { IAddress } from "src/data/homes/types/homeTypes"

// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
enum T {
  COUNTRY = "country",
  STREET_ADDRESS = "street_address",
  STREET_NUMBER = "street_number",
  POSTAL_CODE = "postal_code",
  ROUTE = "route",
  CITY1 = "locality",
  CITY2 = "postal_town",
  REGION1 = "administrative_area_level_1",
  REGION2 = "administrative_area_level_2",
  FLOOR = "floor",
  ROOM = "room",
}

function findType(c: google.maps.GeocoderAddressComponent, type: string) {
  const found = c.types.find((t) => t === type)
  return found ? c.long_name : ""
}

function findShortType(c: google.maps.GeocoderAddressComponent, type: string) {
  const found = c.types.find((t) => t === type)
  return found ? c.short_name : ""
}

function findRegion(region: string) {
  return region.length === 2 ? region : ""
}

export function extractAddressData(
  addressComponents: google.maps.GeocoderAddressComponent[]
): IAddress {
  let streetAddress: string = ""
  let postalCode: string = ""
  let country: string = ""
  let streetNumber: string = ""
  let route: string = ""
  let city: string = ""
  let region: string = ""
  let floor: string = ""
  let room: string = ""

  addressComponents.forEach((c) => {
    if (!streetAddress) {
      streetAddress = findType(c, T.STREET_ADDRESS)
    }
    if (!country) {
      country = findShortType(c, T.COUNTRY)
    }
    if (!streetNumber) {
      streetNumber = findType(c, T.STREET_NUMBER)
    }
    if (!postalCode) {
      postalCode = findType(c, T.POSTAL_CODE)
    }
    if (!route) {
      route = findType(c, T.ROUTE)
    }
    if (!city) {
      city = findType(c, T.CITY1) || findType(c, T.CITY2)
    }
    if (!region) {
      region =
        findRegion(findShortType(c, T.REGION1)) ||
        findRegion(findShortType(c, T.REGION2))
    }
    if (!floor) {
      floor = findType(c, T.FLOOR)
    }
    if (!room) {
      room = findType(c, T.ROOM)
    }
  })

  const street_name1 =
    streetAddress || `${streetNumber}${route ? ` ${route}` : ""}`.trim()
  const street_name2 = `${room}${floor ? ` ${floor}` : ""}`.trim()

  return {
    street_name1,
    street_name2,
    post_code: postalCode,
    country,
    city,
    region,
  }
}
