/* eslint-disable @typescript-eslint/no-explicit-any */
/* https://material-ui.com/components/autocomplete/#google-maps-place */

import { useEffect, useMemo, useRef, useState } from "react"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { Skeleton } from "@material-ui/lab"
import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import { GoogleAPI, GoogleApiWrapper } from "google-maps-react"

import { useTranslate } from "src/i18n/useTranslate"

interface IGoogleServices {
  AutoCompleteService: google.maps.places.AutocompleteService | undefined
  GeoCoder: google.maps.Geocoder | undefined
}

export interface IOnLocationSelectedResult {
  lat: number | undefined
  lng: number | undefined
  address: string
  addressComponents: google.maps.GeocoderAddressComponent[] | null
}

type TOnlocationSelected = (arg: IOnLocationSelectedResult) => void

const googleLoaded = () => {
  return Boolean((window as any).google)
}

/**
 * Get more details about the place with the help of PlacesService after an auto-complete prediction place_id
 */
function getAddressLocation(
  address: google.maps.places.AutocompletePrediction,
  callback: TOnlocationSelected,
  mapRef: React.RefObject<HTMLDivElement>
) {
  const placeId = address.place_id
  if (!placeId) return
  if (!mapRef.current) return

  // https://developers.google.com/maps/documentation/javascript/examples/place-details#maps_place_details-typescript
  // There is currently no formatted street_name1 address returned by Autocomplete.
  // We use the PlacesService to get adr_address which contains a formatted street_name1 address in a string HTML element.
  const placesService = new google.maps.places.PlacesService(mapRef.current)

  const request = {
    placeId,
    fields: ["geometry", "address_components", "adr_address"],
  }

  placesService.getDetails(
    request,
    (
      place: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry?.location &&
        place.adr_address &&
        place.address_components
      ) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        const addressComponents = place.address_components

        // We check if street_address is part of the results in addressComponents.
        const checkStreetAddress = addressComponents.find((component) =>
          component.types.includes("street_address")
        )

        // PlacesServices returns a place.adr_address containing a formatted street_name1 address as a string element in the format
        // "<span class="street-address">Baltzarsgatan 23</span>, <span class="postal-code">211 36</span> ..."
        const virtualElement = new DOMParser().parseFromString(
          place.adr_address,
          "text/html"
        )
        const streetAddress = virtualElement
          .getElementsByClassName("street-address")
          .item(0)?.textContent

        // If there is a formatted street_name1 address in place.adr_address but no street address in address_components,
        // we add the formatted street_name1 to the addressComponents array.
        !checkStreetAddress &&
          !!streetAddress &&
          addressComponents.push({
            long_name: streetAddress,
            short_name: streetAddress,
            types: ["street_address"],
          })

        callback({
          lat,
          lng,
          address: address.description,
          addressComponents,
        })
      }
    }
  )
}

const googleServices: IGoogleServices = {
  AutoCompleteService: undefined,
  GeoCoder: undefined,
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

function PlacesAutoComplete({
  label,
  currentLocation = "",
  onLocationSelected,
  google: googleApi, // alias 'google' from 'google-maps-react' to googleApi
  required = false,
  ...props
}: // TODO WEB-378: Determine if we should use the 'googleApi' prop instead of window.google
{
  currentLocation: string
  onLocationSelected: TOnlocationSelected
  google: GoogleAPI
  required?: boolean
} & TextFieldProps) {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState(currentLocation)
  const [defaultValue, setDefaultValue] =
    useState<google.maps.places.AutocompletePrediction | null>()
  const [fetchIsLoading, setFetchIsLoading] = useState(true)
  const [options, setOptions] = useState([])
  const loaded = useRef(false)
  const mapRef = useRef<HTMLDivElement>(null)
  const { t, langKeys } = useTranslate()

  loaded.current = Boolean(google)

  const handleChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const onInputChange = (
    event: React.ChangeEvent<object>,
    value: string,
    reason: "input" | "reset" | "clear"
  ) => {
    switch (reason) {
      case "clear":
        onLocationSelected({
          lat: undefined,
          lng: undefined,
          address: "",
          addressComponents: null,
        })
        break
      case "input":
        // This is used as a fallback for when no matching GMaps location could be found
        onLocationSelected({
          lat: undefined,
          lng: undefined,
          address: value,
          addressComponents: null,
        })
        break
      case "reset":
      default:
        // 'reset' is called when selecting a value in the dropdown, but we will
        // handle that event in 'onSelected' instead
        break
    }
  }

  const onSelected = (
    event: React.ChangeEvent<object>,
    addressPrediction?:
      | string
      | google.maps.places.AutocompletePrediction
      | null
  ) => {
    if (!addressPrediction) {
      return
    }
    getAddressLocation(
      addressPrediction as google.maps.places.AutocompletePrediction,
      onLocationSelected,
      mapRef
    )
  }

  function handleClose(
    event: React.ChangeEvent<object>,
    reason: AutocompleteCloseReason
  ) {
    // TODO WEB-379: Look into handling of close for autocomplete
    if (!currentLocation) {
      return
    }
    onLocationSelected({
      lat: undefined,
      lng: undefined,
      address: inputValue,
      addressComponents: null,
    })
  }

  const fetch = useMemo(
    () =>
      throttle((input: any, callback: any): void => {
        googleServices.AutoCompleteService?.getPlacePredictions(input, callback)
      }, 200),
    []
  )

  useEffect(() => {
    let active = true

    if (!googleServices.AutoCompleteService && googleLoaded()) {
      googleServices.AutoCompleteService =
        new google.maps.places.AutocompleteService()
      googleServices.GeoCoder = new google.maps.Geocoder()
    }

    if (!googleServices.AutoCompleteService) {
      return undefined
    }

    if (inputValue === "") {
      setOptions([])
      return undefined
    }

    setFetchIsLoading(true)
    fetch({ input: inputValue }, (results: any) => {
      if (active) {
        setOptions(results || [])
      }
      setDefaultValue(results ? results[0] : [])
      setFetchIsLoading(false)
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch, currentLocation])

  if (currentLocation && fetchIsLoading && !defaultValue) {
    // waiting for initial fetch to complete
    return <Skeleton variant="rect" />
  }

  return (
    <>
      <Autocomplete
        getOptionLabel={(option: any) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(options) => {
          // Filter out results without main_text_matched_substrings as they do not match elements in the user's input
          // The result is later used for highlighting matched substrings
          return options.filter(
            (option) =>
              !!option.structured_formatting.main_text_matched_substrings
          )
        }}
        options={options}
        autoHighlight
        includeInputInList
        blurOnSelect={true}
        freeSolo
        clearOnEscape
        onChange={onSelected}
        onClose={handleClose}
        defaultValue={defaultValue}
        onInputChange={onInputChange}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={label ?? t(langKeys.address)}
            variant="outlined"
            autoComplete="off"
            fullWidth
            required={required}
            onChange={handleChange}
          />
        )}
        renderOption={(option) => {
          if (!option) return
          const matches =
            option.structured_formatting.main_text_matched_substrings
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          )

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      />
      <div ref={mapRef}></div>
    </>
  )
}

function throttle(func: (input: any, callback: any) => void, wait: number) {
  let isWaiting = false
  return function (input: any, callback: any) {
    if (isWaiting) {
      return
    }
    func(input, callback)
    isWaiting = true
    setTimeout(function () {
      isWaiting = false
    }, wait)
  }
}

// eslint-disable-next-line import/no-default-export
export const GooglePlacesAutoComplete = GoogleApiWrapper({
  apiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
})(PlacesAutoComplete)
// TODO WEB-380: Determine if we should type this? It will look horrible:
// https://stackoverflow.com/questions/59689535/how-to-pass-children-type-to-props-of-hoc-react
